<template>
  <div>
    <v-data-table
      id="content"
      :headers="headers"
      :items="items"
      :items-per-page="5"
      :search="search"
      sort-by="recommendationName"
      :loading="loading"
      :loading-text="$t('user.userRecommendations.loadingWait')"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title
            ><h2>{{ title }}</h2></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('user.userRecommendations.search')"
            single-line
            hide-details
            style="max-width: 300px;"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            color="primary"
            :disabled="loading"
            @click="showSettings()"
          >
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
          <v-menu v-if="userLinks.length > 0" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                icon
                color="primary"
                :disabled="loading"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-text-account</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(itm, i) in userLinks"
                :key="`${i}-userLink-menu`"
                @click="showUserLink(itm)"
              >
                <v-list-item-title>{{ itm.linkName }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </template>
      <template v-slot:item.material="{ item }">
        <span>{{ getMaterialView(item) }}</span>
      </template>
      <template v-slot:item.print="{ item }">
        <v-btn icon color="primary" @click="openRecommendationReport(item)">
          <v-icon>mdi-printer</v-icon></v-btn
        >
      </template>
      <template v-slot:item.email="{ item }">
        <v-btn icon color="primary" @click="emailRecommendationReport(item)">
          <v-icon>mdi-email</v-icon></v-btn
        >
      </template>
      <template v-slot:item.cuttingChart="{ item }">
        <v-btn
          icon
          color="primary"
          :disabled="cuttingChartDisabled(item)"
          @click="openCuttingChartReport(item)"
        >
          <v-icon>mdi-table-large</v-icon></v-btn
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon class="mr-2" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <span>{{
          $t("user.userRecommendations.noUserRecommendationsFound")
        }}</span>
      </template>
    </v-data-table>

    <v-dialog
      v-model="recommendationNameUpdateDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="520px"
    >
      <NameUpdate
        :item="editedItem"
        @cancel="recommendationNameUpdateCancel"
        @save="recommendationNameUpdateSave"
        :key="nameUpdateKey"
      />
    </v-dialog>

    <v-dialog
      v-model="cuttingChartDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="800px"
    >
      <CuttingChartDialog
        :item="editedItem"
        :items="recommendations"
        @cancel="cuttingChartCancel"
        @create="getCuttingChartReport"
      />
    </v-dialog>

    <v-overlay :value="itemOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import NameUpdate from "@/components/common/NameUpdate";
import CuttingChartDialog from "@/components/user/Dashboard/CuttingChartDialog";

import { api } from "@/helpers/ApiCalls";
import { calculations } from "@/helpers/Calculations";

export default {
  name: "UserRecommendations",

  components: {
    NameUpdate,
    CuttingChartDialog,
  },

  props: {
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      items: [],
      search: "",
      loading: false,
      itemOverlay: false,
      userLink: null,
      editedIndex: -1,
      editedItem: {},
      recommendationNameUpdateDialog: false,
      cuttingChartDialog: false,
      nameUpdateKey: 0,
    };
  },

  computed: {
    ...mapGetters([
      "userRecommendations",
      "user",
      "userMachines",
      "userMaterials",
      "productLinesShort",
      "inputUOM"
    ]),
    title() {
      if (this.userLink) {
        return this.userLink.linkName;
      } else {
        return this.$t("user.userRecommendations.title");
      }
    },
    userLinks() {
      if (this.user) {
        const userLinks = [...this.user.userLinks];
        userLinks.unshift({
          id: 0,
          userID: this.user.userID,
          linkName: this.$t("user.userRecommendations.dashboard"),
        });

        return userLinks;
      } else {
        return [];
      }
    },
    recommendations() {
      if (
        this.editedItem == undefined ||
        this.editedItem == null ||
        this.editedItem.input == undefined ||
        this.editedItem.input == null ||
        this.editedItem.input.machine == undefined ||
        this.editedItem.input.machine == null
      ) {
        return [];
      }

      let list = [];

      const recommendations = this.userRecommendations.filter(
        (item) =>
          item.input.machine.machineID ==
            this.editedItem.input.machine.machineID &&
          item.recommendation.specsImperial ==
            this.editedItem.recommendation.specsImperial &&
          item.recommendation.specsMetric ==
            this.editedItem.recommendation.specsMetric
      );

      let blade = this.editedItem.recommendation.blade;
      let tpi = this.getTPI(this.editedItem.recommendation.specsImperial);

      if (this.editedItem.custom.productTypeOverride.length > 0 && 
        this.editedItem.custom.tpiOverride.length > 0) {
          blade = this.editedItem.custom.productTypeOverride;
          tpi = this.editedItem.custom.tpiOverride;
        }

      const standardRecommendations = recommendations.filter(item => 
        item.custom.productTypeOverride.length == 0 &&
        item.recommendation.blade == blade && 
        this.getTPI(item.recommendation.specsImperial) == tpi
      );

      if (standardRecommendations) {
        list.push(...standardRecommendations);
      }

      const customRecommendations = recommendations.filter(item => 
        item.custom.productTypeOverride.length > 0 &&
        item.custom.productTypeOverride == blade && 
        item.custom.tpiOverride == tpi
      );

      if (customRecommendations) {
        list.push(...customRecommendations);
      }

      list = list.map((o) => ({
        userRecommendationID: o.userRecommendationID,
        recommendationName: o.recommendationName,
      }));

      const recommendation = list.find(
        (item) =>
          item.userRecommendationID == this.editedItem.userRecommendationID
      );

      if (recommendation) {
        recommendation.materialGrouping = this.$t("user.userRecommendations.materialGrouping");
        recommendation.selected = true;
      }

      return list;
    },
    metric() {
      return this.inputUOM > 0;
    }
  },

  methods: {
    getMachine(item) {
      if (
        item == undefined ||
        item.input == undefined ||
        item.input.machine == undefined
      ) {
        return "";
      }

      const machine = item.input.machine;
      let machineDescription = "";

      if (machine.userMachineID != undefined && machine.userMachineID > 0) {
        const userMachine = this.userMachines.find(
          (item) => item.userMachineID == machine.userMachineID
        );
        if (userMachine) {
          machineDescription = this.getMachineDescription(userMachine);
        }
      } else {
        machineDescription = this.getMachineDescription(machine);
      }

      return machineDescription;
    },
    getMachineDescription(machine) {
      let machineDescription = "";

      if (machine.machineName != undefined) {
        machineDescription = machine.machineName;
      } else {
        machineDescription = machine.manufacturerName + " " + machine.model;
      }

      return machineDescription;
    },
    getMaterial(item) {
      let materialDescription = "";

      if (
        item == undefined ||
        item.input == undefined ||
        item.input.dimensions == undefined ||
        item.input.dimensions.dimensionDetails == undefined ||
        item.input.dimensions.shape == undefined ||
        item.input.material == undefined
      ) {
        return "";
      }

      const dimensionDetails = item.input.dimensions.dimensionDetails;
      const shape = item.input.dimensions.shape;

      const material = item.input.material;

      if (material.userMaterialID != undefined && material.userMaterialID > 0) {
        const userMaterial = this.userMaterials.find(
          (item) => item.userMaterialID == material.userMaterialID
        );
        if (userMaterial) {
          materialDescription = this.getMaterialDescription(
            this.metric ? dimensionDetails.descriptionMetric : dimensionDetails.descriptionImperial,
            shape.title,
            material.materialName ? userMaterial.materialName : userMaterial.designation
          );
        }
      } else {
        materialDescription = this.getMaterialDescription(
          this.metric ? dimensionDetails.descriptionMetric : dimensionDetails.descriptionImperial,
          shape.title,
          material.designation
        );
      }

      return materialDescription;
    },
    getMaterialDescription(materialDescription, shape, materialName) {
      const shapeTranslated = this.$t(`sawCalc.inputDimensions.${shape}`)
      return `${materialDescription} ${shapeTranslated} ${materialName}`
    },
    getMaterialView(item) {
      return this.getMaterial(item)
    },
    getBandSpeed(item) {
      if (
        item == undefined ||
        item.input == undefined ||
        item.recommendation == undefined
      ) {
        return "";
      }

      let bandSpeed = 0

      const recommendation = item.recommendation;

      if (this.metric) {
          bandSpeed = recommendation.bandSpeedMetric
      } else {
          bandSpeed = recommendation.bandSpeedImperial
      }

      const custom = item.custom;

      if (custom != undefined && custom != null && custom.recommendation != undefined && custom.recommendation != null) {
        if (this.metric) {
            bandSpeed = custom.recommendation.bandSpeedMetric
        } else {
            bandSpeed = custom.recommendation.bandSpeedImperial
        }
      }

      if (this.metric) {
          bandSpeed += " " + this.bandSpeedUOM(this.metric);
      } else {
          bandSpeed += " " + this.bandSpeedUOM(this.metric);
      }

      return bandSpeed;
    },
    getFeedRate(item) {
      if (
        item == undefined ||
        item.input == undefined ||
        item.recommendation == undefined
      ) {
        return "";
      }

      let feedRate = 0

      const recommendation = item.recommendation;

      if (this.metric) {
          feedRate = recommendation.feedRateMetric;
      } else {
          feedRate = recommendation.feedRateImperial;
      }

      const custom = item.custom;

      if (custom != undefined && custom != null && custom.recommendation != undefined && custom.recommendation != null) {
        if (this.metric) {
            feedRate = custom.recommendation.feedRateMetric;
        } else {
            feedRate = custom.recommendation.feedRateImperial;
        }
      }

      if (this.metric) {
          feedRate += " " + this.feedRateUOM(this.metric);
      } else {
          feedRate += " " + this.feedRateUOM(this.metric);
      }

      return feedRate;
    },
    getCutTime(item) {
      if (item == undefined || item.recommendation == undefined) {
        return "";
      }

      const recommendation = item.recommendation;
      let cutTime = recommendation.cutTime;

      const custom = item.custom;

      if (custom != undefined && custom != null && custom.recommendation != undefined && custom.recommendation != null && custom.recommendation.cutTime != undefined && custom.recommendation.cutTime > 0) {
        cutTime = custom.recommendation.cutTime;
      }

      return calculations.secToTime(cutTime);
    },
    getProduct(item) {
      if (item == undefined || item.recommendation == undefined) {
        return "";
      }

      const recommendation = item.recommendation;

      let bladeDescription = recommendation.bladeDescription;
      let specs = this.metric ? recommendation.specsMetric : recommendation.specsImperial;

      const custom = item.custom;

      if (custom != undefined && custom != null && 
        custom.recommendation != undefined && 
        custom.recommendation != null) {
        bladeDescription = custom.recommendation.bladeDescription;
        specs = this.metric ? custom.recommendation.specsMetric : custom.recommendation.specsImperial;
      }

      return bladeDescription + " " + specs;
    },
    bandSpeedUOM(metric) {
      return metric ? "mpm" : "fpm";
    },
    feedRateUOM(metric) {
      return metric ? "mm/min" : "in/min";
    },
    getUserRecommendations(showNotification) {
      this.updateLoading(true);

      let userID = null;
      if (this.userLink) {
        userID = this.userLink.userID;
      }

      this.$store
        .dispatch("getUserRecommendations", {
          userID: userID,
          showNotification: showNotification,
        })
        .then(
          (response) => {
            this.updateLoading(false);

            if (showNotification) {
              const notification = {
                show: true,
                result: true,
                message: response.message,
              };

              this.$store.dispatch("showSnackbar", notification);
            }

            this.updateHeaders();
            this.setItems(this.userRecommendations);

            this.verifyUserMachines();
            this.verifyUserMaterials();
          },
          (error) => {
            this.updateLoading(false);

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            if (api.authenticationError(error.code)) {
              setTimeout(function() {
                router.push({ name: "Home" }).catch(() => {});
              }, api.authErrorRedirectTimeout);
            }
          }
        );
    },
    verifyUserMachines() {
      if (this.userMachines == undefined || this.userMachines.length == 0) {
        const userMachineIDs = this.userRecommendations.filter(
          (item) => item.userMachineID != null
        );

        if (userMachineIDs != undefined && userMachineIDs.length > 0) {
          this.$store
            .dispatch("getUserMachines", {
              showNotification: false,
              userID: this.user.userID,
            })
            .then(() => {
              this.setItems(this.userRecommendations);
            });
        }
      }
    },
    verifyUserMaterials() {
      if (this.userMaterials == undefined || this.userMaterials.length == 0) {
        const userMaterialIDs = this.userRecommendations.filter(
          (item) => item.userMaterialID != null
        );

        if (userMaterialIDs != undefined && userMaterialIDs.length > 0) {
          this.$store
            .dispatch("getUserMaterials", {
              showNotification: false,
              userID: this.user.userID,
            })
            .then(() => {
              this.setItems(this.userRecommendations);
            });
        }
      }
    },
    setItems(userRecommendations) {
      this.items = [];

      userRecommendations.forEach((item) => {
        this.items.push({
          userRecommendationID: item.userRecommendationID,
          recommendationName: item.recommendationName,
          machine: this.getMachine(item),
          material: this.getMaterial(item),
          bandSpeed: this.getBandSpeed(item),
          feedRate: this.getFeedRate(item),
          cutTime: this.getCutTime(item),
          product: this.getProduct(item),
          input: item.input,
          recommendation: item.recommendation,
          custom: item.custom,
          costCalculation: item.costCalculation,
        });
      });
    },
    showSettings() {
      this.$emit("showSettings");
    },
    updateHeaders() {
      this.$emit("updateHeaders");
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.editedItem.name = this.editedItem.recommendationName;

      this.recommendationNameUpdateDialog = true;
      this.nameUpdateKey++;
    },
    recommendationNameUpdateCancel() {
      this.recommendationNameUpdateDialog = false;
    },
    recommendationNameUpdateSave() {
      this.recommendationNameUpdateDialog = false;

      this.editedItem.recommendationName = this.editedItem.name;

      this.itemOverlay = true;

      this.$store.dispatch("updateUserRecommendation", this.editedItem).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.getUserRecommendations(false);
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    deleteItem(item) {
      const action = this.$t("user.userRecommendations.deleteRecord");
      if (!confirm(action)) {
        return;
      }

      this.itemOverlay = true;
      this.$store.dispatch("deleteUserRecommendation", item).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.getUserRecommendations(false);
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    showUserLink(item) {
      if (item && item.id != 0) {
        this.userLink = item;
      } else {
        this.userLink = null;
      }

      this.getUserRecommendations(true);
      this.$emit("userLinkUpdated", this.userLink);
    },
    openRecommendationReport(item) {
      this.updateLoading(true);

      const custom = item.custom;
      
      if (custom != undefined && custom != null) {
        if (custom.recommendation != undefined && custom.recommendation != null) {
          item.recommendation = custom.recommendation;
        }

        if (custom.costCalculation != undefined && custom.costCalculation != null) {
          item.costCalculation = custom.costCalculation;
        }
      }

      item.input.metric = this.metric;

      this.$store.dispatch("openRecommendationReport", item).then(
        (response) => {
          this.updateLoading(false);
          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.updateLoading(false);

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    emailRecommendationReport(item) {
      this.updateLoading(true);

      this.$store.dispatch("emailRecommendationSavedReport", item).then(
        (response) => {
          this.updateLoading(false);
          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.updateLoading(false);

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    openCuttingChartReport(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.cuttingChartDialog = true;
    },
    cuttingChartCancel() {
      this.cuttingChartDialog = false;
    },
    getCuttingChartReport(payload) {
      this.cuttingChartDialog = false;

      this.itemOverlay = true;

      this.$store.dispatch("getCuttingChartReport", payload).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.$emit("updateCuttingCharts");
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    updateLoading(loading) {
      this.loading = loading;
      this.$emit("updateLoading", loading);
    },
    cuttingChartDisabled(item) {
      return (
        item.input.dimensions.shape.id == 1 ||
        item.input.dimensions.shape.id == 3 ||
        item.input.dimensions.shape.id == 4 ||
        item.input.dimensions.shape.id == 5 ||
        item.input.dimensions.shape.id == 6
      );
    },
    getTPI(specs) {
      if (specs != undefined && specs.length > 0) {
        const delimiter = "x";
        const params = specs.split(delimiter);

        if (params.length == 3) {
          return params[2].trim();
        }
      }

      return "";
    }
  },

  mounted() {
    this.getUserRecommendations(true);
  },
};
</script>

<style lang="scss" scoped>
.image-controls {
  position: relative;
  left: 5px;
  top: 7px;
  height: 0px;
}
.image-controls-buttons {
  color: map-get($theme, footer-link-color);
}
.saw-calc-link {
  cursor: pointer;
}
</style>
