<template>
  <v-container fluid class="py-0">
    <v-row justify="center">
      <v-col class="pa-0">
        <Recommendations 
          :headers="recommendationHeaders"
          @showSettings="showRecommendationSettings"
          @updateCuttingCharts="updateCuttingCharts"
          @updateLoading="updateLoading"
          @userLinkUpdated="userLinkUpdated" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="pa-0">
        <CuttingCharts 
          :headers="cuttingChartHeaders"
          :cuttingChartsUpdated="cuttingChartsUpdated"
          :userLink="userLink"
          @showSettings="showCuttingChartSettings"
          @updateLoading="updateLoading" />
      </v-col>
    </v-row>

    <DataTableSettings 
      :items="dataTableItems"
      @updateHeaders="updateHeaders"
      @updateSettings="updateSettings" 
      :key="dataTableSettingsKey" />

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import Recommendations from "@/components/user/Dashboard/Recommendations";
import CuttingCharts from "@/components/user/Dashboard/CuttingCharts";
import DataTableSettings from "@/components/common/DataTableSettings";

export default {
  name: "Dashboard",

  components: {
    Recommendations,
    CuttingCharts,
    DataTableSettings,
    // RecommendationDataTableSettings,
    // CuttingChartDataTableSettings,
  },

  data() {
    return {
      dataTableSettingsDialog: 0,
      recommendationHeadersList: [
        {
          text: this.$t("user.userRecommendations.recommendationNameLabel"),
          value: "recommendationName",
        },
        {
          text: this.$t("user.userRecommendations.machineDescriptionLabel"),
          value: "machine",
        },
        {
          text: this.$t("user.userRecommendations.materialDescriptionLabel"),
          value: "material",
        },
        {
          text: this.$t("user.userRecommendations.bandSpeedLabel"),
          value: "bandSpeed",
        },
        {
          text: this.$t("user.userRecommendations.feedRateLabel"),
          value: "feedRate",
        },
        {
          text: this.$t("user.userRecommendations.cutTimeLabel"),
          value: "cutTime",
        },
        {
          text: this.$t("user.userRecommendations.productDescriptionLabel"),
          value: "product",
        },
        {
          text: this.$t("user.userRecommendations.print"),
          value: "print",
        },
        {
          text: this.$t("user.userRecommendations.email"),
          value: "email",
        },
        {
          text: this.$t("user.userRecommendations.cuttingChart"),
          value: "cuttingChart",
        },
        {
          text: this.$t("user.userRecommendations.edit"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      recommendationHeaders: [],
      cuttingChartHeadersList: [
        {
          text: this.$t("user.cuttingCharts.cuttingChartNameLabel"),
          value: "cuttingChartName",
        },
        {
          text: this.$t("user.cuttingCharts.machineDescriptionLabel"),
          value: "machine",
        },
        {
          text: this.$t("user.cuttingCharts.productDescriptionLabel"),
          value: "product",
        },
        {
          text: this.$t("user.cuttingCharts.dimensionMinLabel"),
          value: "dimensionMin",
        },
        {
          text: this.$t("user.cuttingCharts.dimensionMaxLabel"),
          value: "dimensionMax",
        },
        {
          text: this.$t("user.cuttingCharts.print"),
          value: "print",
        },
        {
          text: this.$t("user.cuttingCharts.excel"),
          value: "excel",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("user.cuttingCharts.email"),
          value: "email",

        },
        {
          text: this.$t("user.cuttingCharts.edit"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      cuttingChartHeaders: [],
      dataTableItems: [],
      cuttingChartsUpdated: 0,
      loading: false,
      userLink: null,
    };
  },

  computed: {
    ...mapGetters([
      "recommendationsUserSettings",
      "cuttingChartsSettings",
      "dataTableSettingsKey",
    ]),
  },

  methods: {
    showRecommendationSettings() {
      this.dataTableSettingsDialog = 0;

      this.updateRecommendationDataTableItems();
      this.$store.dispatch("showDataTableSettingsDialog", true);
    },
    updateRecommendationSettings(params) {
      this.$store.dispatch("saveRecommendationsUserSettings", params);

      this.updateRecommendationHeadersFromSettings(params.headers);
    },
    updateRecommendationHeaders() {
      if (this.recommendationSettings()) {
        this.updateRecommendationHeadersFromSettings(
          this.recommendationsUserSettings.headers
        );
      } else {
        this.recommendationHeaders = [];
        this.recommendationHeaders = [...this.recommendationHeadersList];
      }
    },
    updateRecommendationHeadersFromSettings(headers) {
      var values = headers
        .filter((item) => item.selected)
        .map((item) => {
          return item.value;
        });

      this.recommendationHeaders = this.recommendationHeadersList.filter(
        (item) => values.includes(item.value)
      );
    },
    updateRecommendationDataTableItems() {
      if (this.recommendationSettings()) {
        this.dataTableItems = this.recommendationsUserSettings.headers;
      } else {
        let items = this.recommendationHeadersList.map((o) => ({
          text: o.text,
          value: o.value,
          selected: true,
        }));

        items.sort(function (a, b) {
          if (!a.text || !b.text) {
            return 0;
          }

          var x = a.text.toLowerCase();
          var y = b.text.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        this.dataTableItems = items;
      }

      this.$store.dispatch("reloadUserRecommendationsSettingsComponent");
    },
    recommendationSettings() {
      return (
        this.recommendationsUserSettings &&
        this.recommendationsUserSettings.headers != null
      );
    },
    showCuttingChartSettings() {
      this.dataTableSettingsDialog = 1;

      this.updateCuttingChartDataTableItems();
      this.$store.dispatch("showDataTableSettingsDialog", true);
    },
    updateCuttingChartSettings(params) {
      this.$store.dispatch("saveCuttingChartsSettings", params);

      this.updateCuttingChartHeadersFromSettings(params.headers);
    },
    updateCuttingChartHeaders() {
      if (this.cuttingChartSettings()) {
        this.updateCuttingChartHeadersFromSettings(
          this.cuttingChartsSettings.headers
        );
      } else {
        this.cuttingChartHeaders = [];
        this.cuttingChartHeaders = [...this.cuttingChartHeadersList];
      }
    },
    updateCuttingChartHeadersFromSettings(headers) {
      var values = headers
        .filter((item) => item.selected)
        .map((item) => {
          return item.value;
        });

      this.cuttingChartHeaders = this.cuttingChartHeadersList.filter((item) =>
        values.includes(item.value)
      );
    },
    updateCuttingChartDataTableItems() {
      if (this.cuttingChartSettings()) {
        this.dataTableItems = this.cuttingChartsSettings.headers;
      } else {
        let items = this.cuttingChartHeadersList.map((o) => ({
          text: o.text,
          value: o.value,
          selected: true,
        }));

        items.sort(function (a, b) {
          if (!a.text || !b.text) {
            return 0;
          }

          var x = a.text.toLowerCase();
          var y = b.text.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        this.dataTableItems = items;
      }

      this.$store.dispatch("reloadCuttingChartsSettingsComponent");
    },
    cuttingChartSettings() {
      return (
        this.cuttingChartsSettings && this.cuttingChartsSettings.headers != null
      );
    },
    updateHeaders() {
      if (this.dataTableSettingsDialog == 0) {
        this.updateRecommendationHeaders();
      } else {
        this.updateCuttingChartHeaders();
      }
    },
    updateSettings(params) {
      if (this.dataTableSettingsDialog == 0) {
        this.updateRecommendationSettings(params);
      } else {
        this.updateCuttingChartSettings(params);
      }
    },
    updateCuttingCharts() {
      this.cuttingChartsUpdated += 1;
    },
    updateLoading(loading) {
      if (loading != this.loading) {
        this.loading = loading;
      }
    },
    userLinkUpdated(userLink) {
      this.userLink = userLink;
      this.updateCuttingCharts();
    },
  },

  mounted() {
    this.updateRecommendationHeaders();
    this.updateCuttingChartHeaders();
  },
};
</script>

<style lang="scss" scoped>
.image-controls {
  position: relative;
  left: 5px;
  top: 7px;
  height: 0px;
}
.image-controls-buttons {
  color: map-get($theme, footer-link-color);
}
.saw-calc-link {
  cursor: pointer;
}
</style>