<template>
  <div>
    <v-card outlined>
      <v-form ref="itemForm">
        <v-card-text class="pa-0 px-3">
          <v-container fluid class="pa-0 px-3">
            <v-row>
              <v-overlay :absolute="true" :value="itemOverlay">
                <v-progress-circular
                  indeterminate
                  size="32"
                ></v-progress-circular>
              </v-overlay>

              <v-col class="pl-2 pr-7 pb-2">
                <v-text-field
                  autofocus
                  :label="$t('common.nameLabel')"
                  :disabled="itemOverlay"
                  v-model="item.name"
                  :rules="inputRules"
                  @keydown.enter.prevent="save(item)"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('common.cancel')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="itemOverlay"
                  @click="cancel()"
                ></v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('common.save')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="saveDisabled"
                  @click="save(item)"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NameUpdate",

  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      itemOverlay: false,
      inputRules: [
        (v) => (!!v && this.recommendationName) || this.$t("common.inputRules"),
      ],
    };
  },

  computed: {
    ...mapGetters(["axiosSource"]),
    valid() {
      if (!this.item || this.item.name == undefined || this.item.name == "") {
        return false;
      } else {
        return true;
      }
    },
    saveDisabled() {
      if (!this.valid || this.itemOverlay) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    save() {
      this.$emit("save");
    },
    cancel() {
      this.$emit("cancel", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list .v-list-item--active {
  border-left: 3px solid map-get($theme, secondary-color);
}
.v-input--selection-controls {
  margin-top: 5px;
}
</style>
