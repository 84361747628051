<template>
  <v-card>
    <v-card-title class="card-title">
      <h3>
        {{ $t("user.cuttingChartDialog.title") }}
      </h3>
    </v-card-title>

    <v-card-text>
      <v-container :class="{ 'pa-3': $vuetify.breakpoint.mdAndUp }">
        <v-row>
          <v-col cols="12" sm="3" class="pa-0">
            <v-container class="pa-0">
              <v-row>
                <v-col>
                  <span class="card-title"
                    >{{ $t("user.cuttingChartDialog.materialDimension") }}</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn-toggle
                    depressed
                    tile
                    dark
                    mandatory
                    :value="uom"
                    @change="uomChanged"
                  >
                    <v-btn
                      color="primary"
                      v-html="$t('sawCalc.inputUOM.imperial')"
                      :x-small="$vuetify.breakpoint.smAndDown"
                    ></v-btn>
                    <v-btn
                      color="primary"
                      v-html="$t('sawCalc.inputUOM.metric')"
                      :x-small="$vuetify.breakpoint.smAndDown"
                    ></v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7" offset="-5" class="py-0">
                  <v-text-field
                    autofocus
                    :label="$t('user.cuttingChartDialog.minLabel')"
                    v-model="materialDimension.dimensionMin"
                    class="px-3"
                    :rules="inputRules"
                    type="number"
                    @input="dimensionMinChanged()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7" offset="-5" class="py-0">
                  <v-text-field
                    :label="$t('user.cuttingChartDialog.maxLabel')"
                    v-model="materialDimension.dimensionMax"
                    class="px-3"
                    :rules="inputRules"
                    type="number"
                    @input="dimensionMaxChanged()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7" offset="-5" class="py-0">
                  <v-text-field
                    :label="$t('user.cuttingChartDialog.rangeLabel')"
                    v-model="materialDimension.dimensionRange"
                    class="px-3"
                    :rules="inputRules"
                    type="number"
                    @input="dimensionRangeChanged()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" sm="8" offset-sm="1" offset="0" class="pa-0">
            <v-container class="pa-0">
              <v-row>
                <v-col align="center">
                  <span class="card-title">{{ $t("user.cuttingChartDialog.selectRecommendations") }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0" align="center">
                  <span class="secondary--text pa-0 hint">{{ $t("user.cuttingChartDialog.selectRecommendationsInfo") }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-data-table
                    id="content"
                    :headers="headers"
                    :items="items"
                    :search="search"
                    sort-by="text"
                    :items-per-page="5"
                    @click:row="recommendationClicked"
                    dense
                  >
                    <template v-slot:top>
                      <v-toolbar flat color="white">
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          :label="$t('admin.machines.search')"
                          single-line
                          hide-details
                          style="max-width: 300px;"
                        ></v-text-field>
                      </v-toolbar>
                    </template>
                    <template v-slot:item.selected="{ item }">
                      <v-icon v-show="item.selected">
                        mdi-check-bold
                      </v-icon>
                    </template>
                    <template v-slot:item.materialGrouping="{ item }">
                      <v-text-field
                        :id="`row-${item.userRecommendationID}`"
                        class="pb-4"
                        v-model="item.materialGrouping"
                        hide-details
                        @input="materialGroupingChanged(item)"
                      ></v-text-field>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions
      :class="{
        'padding-action-small': $vuetify.breakpoint.smAndDown,
        'padding-action': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <v-container class="px-1 py-0">
        <v-row>
          <v-col class="pt-0">
            <v-btn
              depressed
              tile
              v-html="$t('user.cuttingChartDialog.cancelButton')"
              class="primary white--text full-width"
              :disabled="dataTableSettingsDialog.loading"
              :loading="dataTableSettingsDialog.loading"
              @click="cancel"
            ></v-btn>
          </v-col>
          <v-col class="pt-0">
            <v-btn
              depressed
              tile
              v-html="$t('user.cuttingChartDialog.createButton')"
              class="primary white--text full-width"
              :disabled="dataTableSettingsDialog.loading || !valid"
              :loading="dataTableSettingsDialog.loading"
              @click="create"
            ></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CuttingChartDialog",

  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      uom: 0,
      headers: [
        {
          text: this.$t("user.cuttingChartDialog.selectedLabel"),
          value: "selected",
        },
        {
          text: this.$t("user.cuttingChartDialog.recommendationLabel"),
          value: "recommendationName",
        },
        {
          text: this.$t("user.cuttingChartDialog.materialGroupingLabel"),
          value: "materialGrouping",
        },
      ],
      originalItems: [],
      search: "",
      materialDimension: {},
      valid: false,
      inputRules: [
        (v) =>
          (!!v && v.length > 0) ||
          this.$t("user.cuttingChartDialog.inputRules"),
      ],
    };
  },

  computed: {
    ...mapGetters(["dataTableSettingsDialog", "inputUOM"]),
  },

  methods: {
    copyItems() {
      this.originalItems = [];

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        const original = JSON.parse(JSON.stringify(item));
        this.originalItems.push(original);
      }
    },
    uomChanged(index) {
      this.uom = index;
    },
    dimensionMinChanged() {
      this.updateValid();
    },
    dimensionMaxChanged() {
      this.updateValid();
    },
    dimensionRangeChanged() {
      this.updateValid();
    },
    recommendationClicked(item) {
      document.getElementById(`row-${item.userRecommendationID}`).focus();
    },
    materialGroupingChanged(item) {
      if (item.materialGrouping && item.materialGrouping.length > 0) {
        item.selected = true;
      } else {
        item.selected = false;
      }

      this.updateValid();
    },
    cancel() {
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];

        const original = this.originalItems.find(
          (itm) => itm.value == item.value
        );

        if (original) {
          item.selected = original.selected;
        }
      }

      this.$emit("cancel");
    },
    create() {
      const input = {
        metric: this.uom == 1,
        dimensionMin: parseFloat(this.materialDimension.dimensionMin),
        dimensionMax: parseFloat(this.materialDimension.dimensionMax),
        dimensionRange: parseFloat(this.materialDimension.dimensionRange),
      };

      const selected = this.items
        .filter((item) => item.selected == true)
        .map((o) => ({
          userRecommendationID: o.userRecommendationID,
          materialGrouping: o.materialGrouping,
        }));

      this.$emit("create", {
        input: input,
        recommendations: selected,
      });
    },
    updateValid() {
      let valid =
        this.materialDimension != undefined &&
        this.materialDimension != null &&
        this.materialDimension.dimensionMin != undefined &&
        this.materialDimension.dimensionMin != null &&
        this.materialDimension.dimensionMax != undefined &&
        this.materialDimension.dimensionMax != null &&
        this.materialDimension.dimensionRange != undefined &&
        this.materialDimension.dimensionRange != null;

      const selected = this.items.filter((item) => item.selected == true);

      this.valid = valid && selected && selected.length > 0;
    },
  },

  mounted() {
    this.copyItems();
    this.uom = this.inputUOM;
  },
};
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__title {
  padding: 20px 20px 0px 20px;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px 20px 0px 10px;
}

.padding-input {
  padding: 0 10px;
}

.padding-action {
  padding: 0px 20px 20px 20px;
}

.padding-action-small {
  padding: 5px 20px 10px 20px;
}

.v-btn {
  max-height: 30px;
  min-height: 23px;
}

.hint {
  font-size: 0.9em;
}
</style>
