<template>
  <div>
    <v-data-table id="content"
      :headers="headers"
      :items="items"
      :items-per-page="5"
      :search="search"
      sort-by="cuttinChartName"
      :loading="loading" 
      :loading-text="$t('user.cuttingCharts.loadingWait')"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title><h2>{{ title }}</h2></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('user.cuttingCharts.search')"
            single-line
            hide-details
            style="max-width: 300px;"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            color="primary"
            :disabled="loading"
            @click="showSettings()"
          >
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.product="{ item }">
        {{ item.metric ? item.productMetric : item.productImperial}}
      </template>
      <template v-slot:item.dimensionMin="{ item }">
        {{ item.metric ? item.dimensionMin + "mm" : item.dimensionMin + "in" }}
      </template>
      <template v-slot:item.dimensionMax="{ item }">
        {{ item.metric ? item.dimensionMax + "mm" : item.dimensionMax + "in" }}
      </template>
      <template v-slot:item.print="{ item }">
        <v-btn
          icon
          color="primary"
          @click="openCuttingChartReport(item)"
        >
          <v-icon>mdi-printer</v-icon></v-btn
        >
      </template>
      <template v-slot:item.excel="{ item }">
        <v-btn
          icon
          color="primary"
          @click="openCuttingChartExcelReport(item)"
        >
          <v-icon>mdi-microsoft-excel</v-icon></v-btn
        >
      </template>

      <template v-slot:item.email="{ item }">
        <v-btn
          icon
          color="primary"
          @click="emailCuttingChartReport(item)"
        >
          <v-icon>mdi-email</v-icon></v-btn
        >
      </template>
    <template v-slot:item.actions="{ item }">
      <v-icon class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon class="mr-2" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
      <template v-slot:no-data>
        <span>{{ $t('user.cuttingCharts.noCuttingChartsFound') }}</span>
      </template>
    </v-data-table>

    <v-dialog 
      v-model="dialog" 
      persistent 
      :fullscreen="$vuetify.breakpoint.smAndDown" 
      max-width="520px">
      <NameUpdate 
        :item="editedItem"
        @cancel="cancel"
        @save="save" />
    </v-dialog>

    <v-overlay :value="itemOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import NameUpdate from "@/components/common/NameUpdate";

import { api } from "@/helpers/ApiCalls";

import { calculations } from "@/helpers/Calculations";

export default {
  name: "CuttingCharts",

  components: {
    NameUpdate,
  },

  props: {
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    cuttingChartsUpdated: {
      type: Number,
      required: true,
    },
    userLink: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      items: [],
      search: "",
      loading: false,
      itemOverlay: false,
      editedIndex: -1,
      editedItem: {},
      dialog: false,
    };
  },

  computed: {
    ...mapGetters(["cuttingCharts", "user", "inputUOM"]),
    title() {
      return this.$t("user.cuttingCharts.title");
    },
    metric() {
      return this.inputUOM > 0;
    },
  },

  methods: {
    getCuttingCharts(showNotification) {
      this.updateLoading(true);

      let userID = null;
      if (this.userLink) {
        userID = this.userLink.userID;
      }

      this.$store
        .dispatch("getCuttingCharts", {
          userID: userID,
          showNotification: showNotification,
        })
        .then(
          (response) => {
            this.updateLoading(false);

            if (showNotification) {
              const notification = {
                show: true,
                result: true,
                message: response.message,
              };

              this.$store.dispatch("showSnackbar", notification);
            }

            this.updateHeaders();
            this.setItems(this.cuttingCharts);
          },
          (error) => {
            this.updateLoading(false);

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            if (api.authenticationError(error.code)) {
              setTimeout(function () {
                router.push({ name: "Home" }).catch(() => {});
              }, api.authErrorRedirectTimeout);
            }
          }
        );
    },
    setItems(cuttingCharts) {
      this.items = [];

      cuttingCharts.forEach((item) => {
        let dimensionMin = item.dimensionMin;
        
        if (item.metric && !this.metric) {
          dimensionMin = dimensionMin / calculations.conversion;
        }
        else if (!item.metric && this.metric) {
          dimensionMin = dimensionMin * calculations.conversion;
        }

        dimensionMin = parseInt(dimensionMin)

        let dimensionMax = item.dimensionMax;
        
        if (item.metric && !this.metric) {
          dimensionMax = dimensionMax / calculations.conversion;
        }
        else if (!item.metric && this.metric) {
          dimensionMax = dimensionMax * calculations.conversion;
        }

        dimensionMax = parseInt(dimensionMax)

        let dimensionRange = item.dimensionRange;
        
        if (item.metric && !this.metric) {
          dimensionRange = dimensionRange / calculations.conversion;
        }
        else if (!item.metric && this.metric) {
          dimensionRange = dimensionRange * calculations.conversion;
        }

        dimensionRange = parseInt(dimensionRange)

        this.items.push({
          cuttingChartID: item.cuttingChartID,
          cuttingChartName: item.cuttingChartName,
          userID: item.userID,
          metric: this.metric,
          machine: item.machine,
          productMetric: item.productMetric,
          productImperial: item.productImperial,
          dimensionMin: dimensionMin,
          dimensionMax: dimensionMax,
          dimensionRange: dimensionRange,
          cuttingChartMaterialGroups: item.cuttingChartMaterialGroups,
          print: 0,
          email: 0,
          excel:0,
        });
      });
    },
    showSettings() {
      this.$emit("showSettings");
    },
    updateHeaders() {
      this.$emit("updateHeaders");
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.editedItem.name = this.editedItem.cuttingChartName;

      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    save() {
      this.dialog = false;

      this.editedItem.cuttingChartName = this.editedItem.name;

      this.itemOverlay = true;

      this.$store.dispatch("updateCuttingChart", this.editedItem).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.getCuttingCharts(false);
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    deleteItem(item) {
      const action = this.$t("user.cuttingCharts.deleteRecord");
      if (!confirm(action)) {
        return;
      }

      this.itemOverlay = true;
      this.$store.dispatch("deleteCuttingChart", item).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.getCuttingCharts(false);
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    openCuttingChartExcelReport(item) {
      this.updateLoading(true);

      this.$store.dispatch("openCuttingChartExcelReport", item).then(
        (response) => {
          this.updateLoading(false);
          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.updateLoading(false);

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    openCuttingChartReport(item) {
      this.updateLoading(true);

      this.$store.dispatch("openCuttingChartReport", item).then(
        (response) => {
          this.updateLoading(false);
          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.updateLoading(false);

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    emailCuttingChartReport(item) {
      this.updateLoading(true);

      this.$store.dispatch("emailCuttingChartReport", item).then(
        (response) => {
          this.updateLoading(false);
          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.updateLoading(false);

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    updateLoading(loading) {
      this.loading = loading;
      this.$emit("updateLoading", loading);
    },
  },

  watch: {
    cuttingChartsUpdated() {
      this.getCuttingCharts(true);
    },
  },

  mounted() {
    this.getCuttingCharts(true);
  },
};
</script>

<style lang="scss" scoped>
.image-controls {
  position: relative;
  left: 5px;
  top: 7px;
  height: 0px;
}
.image-controls-buttons {
  color: map-get($theme, footer-link-color);
}
.saw-calc-link {
  cursor: pointer;
}
</style>